'use client'
import { Message } from '@locmod/intl'
import Image from 'next/image'
import { constants } from 'helpers'
import appFooterAbstractionImage from 'public/images/app-footer-absctraction.svg?url'

import { Href } from 'components/navigation'
import { Icon, Logo } from 'components/ui'
import { navMessages } from 'compositions/nav'

import messages from './messages'


const year = new Date().getFullYear()

type AppFooterProps = {
  className?: string
}

const topRowLinkClassName = 'text-label-16 font-medium fill-grey-80 text-grey-80 hocus:text-grey-90 hocus:fill-grey-90'
const bottomRowLinkClassName = 'text-label-16 font-medium text-grey-70 hocus:text-grey-90 hocus:fill-grey-90'

const Footer: React.FC<AppFooterProps> = (props) => {
  const { className } = props

  return (
    <footer className={className}>
      <div className="mt-6 sm:mt-10 relative w-full h-20 overflow-hidden bg-dots-gray-8 bg-size-5 bg-bottom">
        <Image
          className="w-auto h-[90%] absolute bottom-0 right-0"
          src={appFooterAbstractionImage}
          alt=""
        />
      </div>
      <div className="px-6 sm:px-10 border-t border-solid border-bg-20">
        <div className="py-6 sm:flex items-center">
          <div className="flex-1 space-x-4">
            <Href className={topRowLinkClassName} message={navMessages.about} toTab={constants.links.about} />
            <Href className={topRowLinkClassName} message={navMessages.docs} toTab={constants.links.docs} />
            <Href className={topRowLinkClassName} message={navMessages.azur} toTab={constants.links.azur} />
          </div>
          <div className="flex items-center space-x-5 -sm:mt-5">
            <Href className={topRowLinkClassName} toTab={constants.links.twitter} aria-label="Twitter">
              <Icon className="size-6" name="interface/twitter" />
            </Href>
            <Href className={topRowLinkClassName} toTab={constants.links.medium} aria-label="Medium">
              <Icon className="size-6" name="interface/medium" />
            </Href>
            <Href className={topRowLinkClassName} toTab={constants.links.discord} aria-label="Discord">
              <Icon className="size-6" name="interface/discord" />
            </Href>
          </div>
        </div>
        <div className="py-6 sm:flex items-center border-t border-solid border-bg-20 text-grey-70">
          <div className="flex-1 space-x-4">
            {/*<Href className={bottomRowLinkClassName} message={navMessages.audit} toTab={constants.links.audits} />*/}
            <Href className={bottomRowLinkClassName} message={navMessages.privacy} toTab={constants.links.privacy} />
            <Href className={bottomRowLinkClassName} message={navMessages.terms} toTab={constants.links.terms} />
          </div>
          <div className="-sm:mt-5">
            <Message value={messages.designedBy} />
            &nbsp;
            <Href className={`${bottomRowLinkClassName} font-semibold`} message="Flatstudio" toTab="https://flatstudio.co/" />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
