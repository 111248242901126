'use client'

import React from 'react'
import cx from 'clsx'
import { useAccount } from 'wagmi'
import { constants } from 'helpers'
import { PointsLevelName } from 'helpers/enums'

import { Icon } from 'components/ui'
import { Href } from 'components/navigation'
import { useUserWaveStats, useWaveInfo, type LevelData } from 'views/ScoreWavePage/utils'


type WaveLevelProps = {
  level?: LevelData
  isLink?: boolean
}

const WaveLevel: React.FC<WaveLevelProps> = ({ level, isLink }) => {
  const { address } = useAccount()
  const { data: waveData, isFetching: isWaveFetching } = useWaveInfo(!level)
  const { data, isFetching, isRefetching } = useUserWaveStats(!level)

  if (!address) {
    return null
  }

  if (isFetching || isRefetching || isWaveFetching) {
    return <div className="h-8 w-32 bone rounded-2" />
  }

  if (!level && !waveData?.wave && !data) {
    return null
  }

  const { name, boost } = data?.levelDescription || {
    name: PointsLevelName.Grey,
    boost: '1',
  }

  return (
    <Href
      to={isLink ? constants.links.waves : undefined}
      className={
        cx('py-0.5 pr-0.5 pl-2.5 flex items-center rounded-2', {
          'bg-wave-gr-grey': name === PointsLevelName.Grey,
          'bg-wave-gr-mist': name === PointsLevelName.Mist,
          'bg-wave-gr-sky': name === PointsLevelName.Sky,
          'bg-wave-gr-blue': name === PointsLevelName.Blue,
          'bg-wave-gr-ultramarine': name === PointsLevelName.Ultramarine,
          'bg-wave-gr-bright': name === PointsLevelName.Bright,
          'bg-wave-gr-brilliant': name === PointsLevelName.Brilliant,
          'bg-wave-gr-royal': name === PointsLevelName.Royal,
        })
      }
    >
      <div className="text-label-16 font-semibold mr-2 text-white">{name}</div>
      <div className="bg-bg-20 flex items-center rounded-l-[3px] rounded-r-[6px] py-[4.5px] px-2 text-grey-80">
        <span className="font-semibold text-label-16">x{boost}</span>
        <Icon className="ml-1 size-4" name="interface/azuro-score" />
      </div>
    </Href>
  )
}

export default React.memo(WaveLevel)
