import clsx from 'clsx'
import { Message } from '@locmod/intl'
import { constants } from 'helpers'

import AddTokenToWalletButton from 'compositions/app/AddTokenToWalletButton/AddTokenToWalletButton'

import messages from './messages'


type AddTokenSectionProps = {
  className?: string
}

const AddTokenSection: React.FC<AddTokenSectionProps> = (props) => {
  const { className } = props

  return (
    <div className={clsx(className, 'relative py-[1.875rem] px-6 border-t border-b border-bg-20 border-b-transparent text-center')}>
      <span className="sm:hidden absolute top-0 left-1/2 -translate-y-1/2 -translate-x-1/2 size-2 aspect-square rounded-full bg-brand-50 shadow-[0_0_0_2px_#fff]" />
      <Message className="text-label-16 font-medium text-grey-80" value={messages.title} />
      <AddTokenToWalletButton
        className="w-full mt-4"
        token={constants.azurToken}
        rightIcon="tokens/azuro"
        withShortTitle
      />
    </div>
  )
}

export default AddTokenSection
