'use client'
import clsx from 'clsx'
import { openModal } from '@locmod/modal'
import { ConnectorName } from 'wallet'
import { useAccount, useDisconnect } from 'wagmi'
import { shortenAddress } from 'helpers'

import { Button, buttonMessages } from 'components/inputs'
import { WalletIcon } from 'components/dataDisplay'
import { Icon } from 'components/ui'


const activeButtonClassName = clsx(
  'group h-11 bg-bg-10 hocus:bg-bg-20 pr-4 pl-1.5 rounded-full'
)

const ConnectButton: React.FC<{ className?: string }> = (props) => {
  const { className } = props
  const { address } = useAccount()
  const { disconnect } = useDisconnect()

  const handleConnectClick = () => {
    openModal('ConnectModal')
  }

  return Boolean(address) ? (
    <Button
      className={clsx(className, activeButtonClassName)}
      styling={null}
      size={44}
      title={shortenAddress(address, 3, 4)}
      leftNode={<Icon className="size-8 mr-1.5 p-1 text-grey-90 bg-bg-10 border border-bg-20 rounded-full" name="interface/user-avatar" />}
      rightNode={<Icon className="size-5 text-grey-70 ml-2 group-hocus:text-grey-90" name="interface/log-out" />}
      onClick={() => disconnect()}
    />
  ) : (
    <Button
      styling="secondary"
      className={clsx(className, 'group')}
      size={44}
      title={buttonMessages.connectWalletDynamic}
      rightNode={
        (
          <div className="flex items-center ml-2">
            <div className="flex items-center justify-center w-6 h-6 box-content rounded-full bg-white border-2 border-solid border-grey-90 z-10 group-hocus:border-brand-50 transition-all">
              <WalletIcon className="w-[60%]" name={ConnectorName.Injected} />
            </div>
            <div className="flex items-center justify-center w-6 h-6 rounded-full bg-white -ml-2">
              <WalletIcon className="w-[60%]" name={ConnectorName.WalletConnect} />
            </div>
          </div>
        )
      }
      onClick={handleConnectClick}
    />
  )
}

export default ConnectButton
