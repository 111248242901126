'use client'

import { useState, type MouseEventHandler } from 'react'
import { getWalletClient } from '@wagmi/core'
import { config } from 'wallet'
import { watchAsset } from 'viem/actions'
import { openModal } from '@locmod/modal'
import { isUserRejectedRequestError } from 'helpers'
import type { ChainId } from 'helpers/enums'

import { Button, type ButtonProps, type AppSizedProps } from 'components/inputs'
import ConnectButtonWrapper from 'compositions/app/ConnectButtonWrapper/ConnectButtonWrapper'

import messages from './messages'


type AddTokenToWalletButtonProps = Partial<AppSizedProps> & {
  className?: string
  token: {
    address: Address
    decimals: number
    image: string
    symbol: string
    chainId: ChainId
  }
  rightIcon?: ButtonProps['rightIcon']
  withShortTitle?: boolean
}

const AddTokenToWalletButton: React.FC<AddTokenToWalletButtonProps> = (props) => {
  const { className, token, styling = 'tertiary', rightIcon, size = 44, withShortTitle } = props
  const { address, decimals, image, symbol, chainId } = token

  const [ isSubmitting, setIsSubmitting ] = useState(false)

  const handleClick: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement> = (event) => {
    event?.preventDefault()
    event?.stopPropagation()

    if (isSubmitting) {
      return
    }

    const action = async () => {
      setIsSubmitting(true)

      try {
        const walletClient = await getWalletClient(config, {
          chainId,
        })

        const result = await watchAsset(walletClient, {
          type: 'ERC20',
          options: {
            address,
            decimals,
            symbol,
            image,
          },
        })
      }
      catch (error: any) {
        if (!isUserRejectedRequestError(error)) {
          openModal('ErrorModal', {
            error,
          })
        }
      }

      setIsSubmitting(false)
    }

    action()
  }

  const handleAnyButtonClick: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement> = (event) => {
    event?.preventDefault()
    event?.stopPropagation()
  }

  const title = {
    ...withShortTitle ? messages.shorTitle : messages.longTitle,
    values: {
      symbol,
    },
  }

  return (
    <ConnectButtonWrapper requiredChainId={chainId} onAnyButtonClick={handleAnyButtonClick}>
      <Button
        className={className}
        title={title}
        styling={styling}
        rightIcon={rightIcon}
        size={size}
        loading={isSubmitting}
        onClick={handleClick}
      />
    </ConnectButtonWrapper>
  )
}

export default AddTokenToWalletButton
