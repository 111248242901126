import React, { type MouseEventHandler } from 'react'
import { openModal } from '@locmod/modal'
import { useWallet } from 'wallet'

import { Button, buttonMessages, type ButtonProps } from 'components/inputs'


type ConnectButtonWrapperProps = {
  children: React.ReactElement<ButtonProps>
  requiredChainId: number
  onAnyButtonClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>
}

const ConnectButtonWrapper: React.FC<ConnectButtonWrapperProps> = ({ requiredChainId, children, onAnyButtonClick }) => {
  const { account, chainId } = useWallet()
  const { onClick, title, disabled, ...props } = children.props

  if (!account) {
    const handleClick: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement> = (event) => {
      openModal('ConnectModal')

      if (typeof onAnyButtonClick === 'function') {
        onAnyButtonClick(event)
      }
    }

    return (
      <Button
        title={buttonMessages.connectWallet}
        {...props}
        onClick={handleClick}
      />
    )
  }

  const handleClick: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement> = (event) => {
    const isRightNetwork = requiredChainId === chainId

    if (typeof onAnyButtonClick === 'function') {
      onAnyButtonClick(event)
    }

    if (!isRightNetwork) {
      openModal('SwitchNetworkModal', {
        chainId: requiredChainId,
        onFinish: () => {
          if (typeof children.props.onClick === 'function') {
            children.props.onClick(event)
          }
        },
      })
    }
    else if (typeof children.props.onClick === 'function') {
      children.props.onClick(event)
    }
  }

  return React.cloneElement(children, {
    onClick: handleClick,
  })
}

export default ConnectButtonWrapper
