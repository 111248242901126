'use client'
import { Message } from '@locmod/intl'
import clsx from 'clsx'
import { type Dispatch, type MouseEventHandler, type SetStateAction, useEffect, useState, type MouseEvent } from 'react'
import { constants } from 'helpers'

import { ButtonBase, buttonMessages } from 'components/inputs'
import { ActiveLink, Href } from 'components/navigation'
import { Icon, type IconName, Logo } from 'components/ui'
import { useMedia } from 'contexts'
import { navMessages } from 'compositions/nav'
import ConnectButton from 'compositions/app/ConnectButton/ConnectButton'

import WaveLevel from 'views/ScoreWavePage/components/WaveLevel/WaveLevel'
import AddTokenSection from './components/AddTokenSection/AddTokenSection'
import messages from './messages'


type CommonProps = {
  isMenuOpen: boolean | undefined
  setMenuOpen: Dispatch<SetStateAction<boolean | undefined>>
}

type LinkProps = {
  message: Intl.Message | string
  icon: IconName
  to?: string
  toTab?: string
  activeRegex?: string
}

const NavLink: React.FC<LinkProps> = (props) => {
  const { message, icon, to, toTab, activeRegex } = props

  const rootClassName = clsx(
    'p-3 w-full h-11 flex items-center overflow-y-hidden overflow-x-auto border border-solid no-scrollbar border-transparent transition rounded-full',
    'text-grey-70 hocus:text-grey-90 hocus:border-bg-10 hocus:bg-bg-10'
  )

  return (
    <ActiveLink
      className={rootClassName}
      activeClassName="bg-bg-10 !border-bg-30 !text-brand-50"
      to={to}
      toTab={toTab}
      activeRegex={activeRegex}
    >
      <Icon
        className="size-5 mr-2"
        name={icon}
      />
      <Message className="text-label-16 font-semibold text-grey-90" value={message} />
      {
        Boolean(toTab) && (
          <Icon
            className="size-4 ml-2 text-grey-70"
            name="interface/extrernal-link"
          />
        )
      }
    </ActiveLink>
  )
}

const LeftSidebar: React.FC<CommonProps> = (props) => {
  const { isMenuOpen, setMenuOpen } = props

  const handleClose: MouseEventHandler<any> = (event) => {
    event.stopPropagation()
    setMenuOpen(false)
  }

  const rootClassName = clsx(
    '-xl:fixed top-0 left-0 size-full bg-white transition ease-in-out-quadratic xl:shadow-none',
    'side-section row-start-3 -xl:z-30 xl:col-start-2 xl:bg-dots-gray-8 bg-size-5 bg-right-bottom',
    'xl:sticky xl:top-16 xl:max-h-[calc(100vh-4rem)] overflow-y-auto overflow-x-hidden',
    isMenuOpen ? '-xl:shadow-lvl2' : '-xl:-translate-x-full'
  )

  const groupLabelClassName = 'px-3 py-0.5 mb-1.5 text-grey-70 text-label-14 font-medium uppercase tracking-wide'

  return (
    <aside className={rootClassName}>
      <div className="-xl:hidden absolute -z-10 top-0 left-0 w-full h-[70vh] bg-gradient-to-br from-white" />
      <nav className="-xl:mt-top-notice min-h-full-top-notice h-full z-0 pt-18 flex flex-col" aria-label="Primary" onClick={handleClose}>
        <section className="px-3">
          <Message className={groupLabelClassName} tag="h4" value={messages.earn} />
          <NavLink
            message={navMessages.pools}
            to={constants.links.liquidity}
            activeRegex={`^${constants.links.liquidity}`}
            icon="interface/liquidity-pools"
          />
          <NavLink
            message={navMessages.staking}
            to={constants.links.staking}
            activeRegex={`^${constants.links.staking}`}
            icon="interface/staking"
          />
          <NavLink
            message={navMessages.dexFarming}
            to={constants.links.dexFarming}
            activeRegex={`^${constants.links.dexFarming}`}
            icon="interface/dex-liquidity"
          />
        </section>
        <section className="px-3 mt-8">
          <Message className={groupLabelClassName} tag="h4" value={messages.myAzuro} />
          <NavLink
            message={navMessages.waves}
            to={`${constants.links.app}/waves`}
            icon="interface/azuro-score"
          />
          <NavLink
            message={navMessages.airdrop}
            to={constants.links.airdrop}
            icon="interface/airdrop"
          />
          <NavLink
            message={navMessages.vesting}
            to={`${constants.links.app}/vesting`}
            icon="interface/vesting"
          />
          {/*<NavLink*/}
          {/*  message={navMessages.govern}*/}
          {/*  to={constants.links.governance}*/}
          {/*  icon="interface/governance"*/}
          {/*/>*/}
        </section>
        <div className="w-full mt-auto pt-8" onClick={(event) => event.stopPropagation()}>
          <AddTokenSection />
          <ButtonBase
            className="w-full border-t border-bg-10 rounded-t-8 sm:hidden"
            styling={null}
            size={44}
            onClick={handleClose}
          >
            <Message className="text-label-14 font-semibold uppercase text-grey-70" value={buttonMessages.close} />
          </ButtonBase>
        </div>
      </nav>
    </aside>
  )
}

const TopNavBar: React.FC<CommonProps> = (props) => {
  const { isMenuOpen, setMenuOpen } = props
  const { isMobileView } = useMedia()

  const baseLineClassName = 'absolute top-1/2 left-0.5 w-4 h-[2px] bg-grey-70 rounded-full transition ease-in-out-quadratic'

  const handleButtonClick = (event: MouseEvent) => {
    event.stopPropagation()
    setMenuOpen((v) => !v)
  }

  return (
    <>
      <div className="xl:side-section xl:col-start-2 sticky top-0 z-40 h-16 pl-6 flex items-center w-full bg-white">
        <button
          className="relative xl:hidden size-5 mr-4 -mt-px transition"
          type="button"
          aria-label="Toggle menu"
          onClickCapture={handleButtonClick}
        >
          <div className={clsx(baseLineClassName, isMenuOpen ? 'rotate-45' : '-translate-y-1.5')} />
          <div className={clsx(baseLineClassName, 'm-auto', isMenuOpen ? 'scale-x-0' : '')} />
          <div className={clsx(baseLineClassName, isMenuOpen ? '-rotate-45' : 'translate-y-1.5 scale-x-75 origin-left')} />
        </button>
        <Href to="/">
          <Logo className="xl:hidden h-4" onlyText />
          <Logo className="-xl:hidden h-6" />
        </Href>
      </div>
      <div className="sticky top-0 z-40 h-16 pr-6 flex items-center justify-end w-full bg-white">
        <div className="ml-auto flex items-center">
          {
            !isMobileView && (
              <WaveLevel isLink />
            )
          }
          <ConnectButton className="ml-2" />
        </div>
      </div>
    </>
  )
}

const LayoutNav: React.FC = () => {
  const [ isMenuOpen, setMenuOpen ] = useState<boolean>()

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('body-scroll-frozen')

      const clickHandler = () => {
        setMenuOpen(false)
      }

      const timerId = setTimeout(() => {
        document.body.addEventListener('click', clickHandler)
      }, 0)

      return () => {
        document.body.classList.remove('body-scroll-frozen')
        document.body.removeEventListener('click', clickHandler)
        clearTimeout(timerId)
      }
    }
  }, [ isMenuOpen ])

  return (
    <>
      <TopNavBar isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
      <LeftSidebar isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
    </>
  )
}

export default LayoutNav
