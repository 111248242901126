import { toLocaleString } from 'helpers'


export * from './hooks'
export { classNamesByLevelName } from './styles'


export const formatPoints = (points: number | string | undefined | null) => (
  toLocaleString(points || 0, { cutFractionalZero: true })
)
