const shortenAddress = (address: string | undefined, countBefore: number = 6, countAfter: number = 4) => {
  if (!address) {
    return '0x...'
  }

  return `${address.substr(0, countBefore + 2)}...${address.substr(-1 * countAfter)}`

}

export default shortenAddress
