import clsx from 'clsx'
import { PointsLevelName } from 'helpers/enums'


const textGradientDefault = 'bg-clip-text text-transparent'

export const classNamesByLevelName: Record<PointsLevelName, { box: string, bar: string, text: string }> = {
  [PointsLevelName.Grey]: {
    box: clsx('bg-bg-20'),
    text: clsx('bg-wave-gr-grey', textGradientDefault),
    bar: clsx('bg-wave-bg-mist'),
  },
  [PointsLevelName.Mist]: {
    box: clsx('bg-wave-bg-mist'),
    text: clsx('bg-wave-gr-mist', textGradientDefault),
    bar: clsx('bg-wave-bg-sky'),
  },
  [PointsLevelName.Sky]: {
    box: clsx('bg-wave-bg-sky'),
    text: clsx('bg-wave-gr-sky', textGradientDefault),
    bar: clsx('bg-wave-bg-blue'),
  },
  [PointsLevelName.Blue]: {
    box: clsx('bg-wave-bg-blue'),
    text: clsx('bg-wave-gr-blue', textGradientDefault),
    bar: clsx('bg-wave-bg-ultramarine'),
  },
  [PointsLevelName.Ultramarine]: {
    box: clsx('bg-wave-bg-ultramarine'),
    text: clsx('bg-wave-gr-ultramarine', textGradientDefault),
    bar: clsx('bg-wave-bg-bright'),
  },
  [PointsLevelName.Bright]: {
    box: clsx('bg-wave-bg-bright'),
    text: clsx('bg-wave-gr-bright', textGradientDefault),
    bar: clsx('bg-wave-bg-brilliant'),
  },
  [PointsLevelName.Brilliant]: {
    box: clsx('bg-wave-bg-brilliant'),
    text: clsx('bg-wave-gr-brilliant', textGradientDefault),
    bar: clsx('bg-wave-bg-royal'),
  },
  [PointsLevelName.Royal]: {
    box: clsx('bg-wave-bg-royal'),
    text: clsx('bg-wave-gr-royal', textGradientDefault),
    bar: clsx('hidden'),
  },
}
