import dayjs from 'dayjs'
import { parseUnits, formatUnits } from 'viem'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { useWallet } from 'wallet'
// import { constants } from 'helpers'
import { restApi } from 'helpers/api'
import type { PointsLevelName } from 'helpers/enums'


// const baseUrl = `/api/v1/public/waves/${constants.azurPointsWaveId}`
const baseUrl = '/api/v1/public/waves/active'

export type LevelData = {
  level: number
  name: PointsLevelName
  boost: `${number}`
  pointsNeeded: `${number}`
  comment: string
}

// https://dev-api.azuro.org/api/v1/public/gateway/docs#/Wave/WaveController_activateParticipantLevel
type ActivationResponse = {}

export const useActivation = () => {
  const { account } = useWallet()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => restApi.get<ActivationResponse>(`${baseUrl}/participants/${account?.toLowerCase()}/activate`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'waves', 'stats', account ] })
      queryClient.invalidateQueries({ queryKey: [ 'waves', 'leaderboard', account ] })
    },
  })
}


// https://dev-api.azuro.org/api/v1/public/gateway/docs#/Wave/WaveController_getParticipantStats
export type StatsResponse = {
  address: Address
  waveId: 1
  levelActivated: boolean
  initialLevel: number
  level: number

  // points by categories
  betPoints: `${number}`
  dexPoints: `${number}`
  liqudityPoints: `${number}`
  stakingPoints: `${number}`
  leaderboardPoints: `${number}`
  manualPoints: `${number}`
  /** "2.100000", final points without level multiplier */
  points: `${number}`
  /** "2.100000", final points with level multiplier ('boost') */
  multipliedPoints: `${number}`

  sharePercent: `${number}`
  levelDescription: LevelData
}

export const useUserWaveStats = (enabled?: boolean) => {
  const { account } = useWallet()

  const queryFn = async () => {
    const { data } = await restApi.get<StatsResponse>(`${baseUrl}/participants/${account?.toLowerCase()}/stats`)
    const { address, levelActivated, level, levelDescription, points, betPoints, dexPoints, stakingPoints, multipliedPoints } = data

    return {
      address,
      isActivated: levelActivated,
      level,
      levelDescription,
      points,
      betPoints,
      dexPoints,
      stakingPoints,
      totalMultipliedPoints: multipliedPoints,
    }
  }

  return useQuery({
    queryKey: [ 'waves', 'stats', account ],
    queryFn,
    enabled: enabled && Boolean(account),
  })
}

type InfoResponse = {
  wave: {
    id: number,
    startsAt: string,
    endsAt: string,
    totalPoints: string,
    totalMultipliedPoints: string
  } | null,
  participantsCount: number
}

export const useWaveInfo = (enabled = true) => {
  const queryFn = async () => {
    const { data } = await restApi.get<InfoResponse>(baseUrl)

    return data
  }

  return useQuery({
    queryKey: [ 'waves', 'info' ],
    queryFn,
    enabled,
  })
}

type LevelsResponse = LevelData[]

export const useWaveLevels = (enabled?: boolean) => {
  const queryFn = async () => {
    const { data } = await restApi.get<LevelsResponse>(`${baseUrl}/levels`)

    // it's already sorted in API at this moment, but just a protection
    // levels starts from 0 ("Grey" level, non-active)
    // with this sort we're ensuring that we can take level by index - levels[user.level]
    return [ ...data ].sort((a, b) => a.level - b.level)
  }

  return useQuery({
    queryKey: [ 'waves', 'levels' ],
    queryFn,
    refetchOnWindowFocus: false,
    enabled,
  })
}

type PeriodApi = {
  id: number
  // ISO String "2024-05-13T00:00:00.000Z"
  startsAt: string
  // ISO String "2024-05-20T00:00:00.000Z"
  // ATTN actually, it's a startsAt of next period
  endsAt: string
  totalPoints: `${number}`
  waveId: 1
}

type PeriodsResponse = PeriodApi[]

export type Period = {
  id: string
  title: string
  periodStartsAt: number | undefined
  isBonusPreCalc: boolean
}

const totalPeriod: Period = {
  id: 'total',
  title: 'Total',
  periodStartsAt: undefined,
  isBonusPreCalc: false,
}

// TODO use server fetch action instead of hook - added on 20.05.2024 by sonatskiy
//  https://nextjs.org/docs/app/building-your-application/data-fetching/fetching-caching-and-revalidating#fetching-data-on-the-server-with-fetch
export const useWavePeriods = (enabled?: boolean) => {
  const queryFn = async () => {
    const { data } = await restApi.get<PeriodsResponse>(`${baseUrl}/periods`)

    // we only need last two, they are DESC sorted
    const periods = [ ...data ]
      .sort((a, b) => b.id - a.id)
      .slice(0, 2)
      .map(({ startsAt, id, endsAt }, index) => {
        const fromTimestamp = new Date(startsAt).getTime()
        const toTimestamp = new Date(endsAt).getTime() - 1000

        return {
          id: String(id),
          title: `${dayjs(fromTimestamp).utc().format('MMM DD')} - ${dayjs(toTimestamp).utc().format('MMM DD')}`,
          // fromTimestamp,
          // toTimestamp,
          periodStartsAt: Math.floor(fromTimestamp / 1000),
          // first in the array, current in time
          isBonusPreCalc: !index,
        } satisfies Period
      })
      .reverse()

    return [
      totalPeriod,
      ...periods,
    ] satisfies Period[]
  }

  return useQuery({
    queryKey: [ 'waves', 'periods' ],
    queryFn,
    initialData: (): Period[] => [ totalPeriod ],
    placeholderData: (): Period[] => [ totalPeriod ],
    refetchOnWindowFocus: false,
    enabled,
  })
}

type LeaderboardTotalApiItem = StatsResponse & {
  position: number
}

type LeaderboardPeriodApiItem = {
  position: number
  address: Address
  points: `${number}`
  wavePeriodId: number
  sharePercent: `${number}`
  expectedPositionMultiplier: `${number}`
}

export type LeaderBoardItem = {
  position: number
  address: Address
  points: `${number}`
  bonusPoints: `${number}` | null
  totalMultipliedPoints: `${number}`
  bonusMultiplier: number | null
  level: number | null
  levelDescription: LevelData | null
}

type LeaderBoardData = LeaderBoardItem[]

export const useLeaderboard = ({ periodStartsAt, enabled }: { periodStartsAt?: number, enabled?: boolean } = {}) => {
  const { account } = useWallet()

  const queryFn = async (): Promise<LeaderBoardData> => {
    const endpoint = periodStartsAt
      ? `${baseUrl}/periods/${periodStartsAt}/leaderboard`
      : `${baseUrl}/leaderboard`

    const { data } = await restApi.get<(LeaderboardTotalApiItem | LeaderboardPeriodApiItem)[]>(endpoint, {
      params: {
        address: account?.toLowerCase(),
      },
    })

    if (periodStartsAt) {
      return (data as LeaderboardPeriodApiItem[]).map((item) => {
        const { address, points, position, expectedPositionMultiplier } = item

        const bnPoints = parseUnits(points, 12)
        const bnMultipliedPoints = bnPoints * parseUnits(expectedPositionMultiplier, 12)
        const totalMultipliedPoints = formatUnits(bnMultipliedPoints, 24) as `${number}`
        const bonusPoints = formatUnits(parseUnits(totalMultipliedPoints, 12) - bnPoints, 12) as `${number}`

        return {
          position,
          address,
          points,
          bonusPoints,
          totalMultipliedPoints,
          bonusMultiplier: +expectedPositionMultiplier,
          level: null,
          levelDescription: null,
        } satisfies LeaderBoardItem
      })
    }

    return (data as LeaderboardTotalApiItem[]).map((item) => {
      const { address, points, position, level, levelDescription, multipliedPoints: totalMultipliedPoints } = item

      return {
        position,
        address,
        points,
        bonusPoints: null,
        bonusMultiplier: null,
        totalMultipliedPoints,
        level,
        levelDescription,
      } satisfies LeaderBoardItem
    })
  }

  return useQuery({
    queryKey: [ 'waves', 'leaderboard', account, periodStartsAt ],
    queryFn,
    enabled,
  })
}
